import React, { useState } from "react";
import client from "../utils/feathers";
import { Text, Card, Loading, Table, Modal, Container, useAsyncList, Row, Col, Tooltip, Input, Button, useInput } from "@nextui-org/react";
import { DeleteIcon, EditIcon, IconButton, LockIcon } from "../Components/Icons";

var services = {};
services.talkgroups = client.service('talkgroups');

export default function Talkgroups() {
  document.title = "OinkDB: Talkgroups";
  
  const keyColumns = [
    { key: "talkgroup", label: "TG ID", width: '-'},
    { key: "tag", label: "Tag", width: '-' },
    { key: "description", label: "Description", width: '-' },
    { key: "action", label: "Action", width: '60' }
  ];

  const [count, setCount] = useState(20);
  const [skip, setSkip] = useState(0);
  const [pages, setPages] = useState(0);
  const [tgToEdit, setTGToEdit] = useState({id: 0, talkgroup: 0, tag: '', description: ''});
  const [showEditModal, setShowEditModal] = useState(false);

  const editTag = useInput();
  const editDescription = useInput();


  async function load({signal, cursor}) {
    var query = {
      $sort: { talkgroup: 1 },
      $limit: count,
      $skip: skip
    };

    const res = await services.talkgroups.find({query: query});
    console.log(cursor || 0, res)
    setSkip(res.skip + res.data.length);
    setPages(Math.ceil(res.total / count));
    return {
      items: res.data, 
      cursor: skip
    }
  }

  const list = useAsyncList({ load });

  function handleKeyAction(item, action) {
    if (action === 'edit') {
      setTGToEdit(item);
      editTag.setValue(item.tag);
      editDescription.setValue(item.description);
      setShowEditModal(true);
    }
  }

  function handleEditSubmit(e) {
    const tg = tgToEdit;

    console.log(tg)

    services.talkgroups.patch(tg.id, {tag: editTag.value, description: editDescription.value}).then((ret) => {
      const index = list.items.findIndex(o => o.id === ret.id);
      list.items[index] = ret;
      setShowEditModal(false);
    })
  }

  function renderTableCell(item, key) {
    if (key === 'action') {
      return (
        <Table.Cell css={{width: '60px'}}>
        <Row justify='right' align='right'>
          <Col css={{display:'flex', width: '25px'}}>
            <Tooltip content='Edit' color='warning'>
              <IconButton onClick={() => handleKeyAction(item, 'edit')}>
                <EditIcon size={20} fill="#307f30" />
              </IconButton>
            </Tooltip>
          </Col>
          <Col css={{display:'flex', width: '25px'}}>
            <Tooltip content='Delete' color='error'>
              <IconButton onClick={() => handleKeyAction(item, 'delete')}>
                <DeleteIcon size={20} fill="#F31260" />
              </IconButton>
            </Tooltip>
          </Col>
        </Row>
        </Table.Cell>
      )
    } else {
      return <Table.Cell>{item[key]}</Table.Cell>
    }
  };

  return (
    <>
      <Modal blur closeButton open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Modal.Header>
          <Text h2>Edit talkgroup: {tgToEdit.talkgroup}</Text>
        </Modal.Header>
        <Modal.Body>
          <Input name="tag" label="Short Tag" value={editTag.value} {...editTag.bindings} fullWidth bordered size='lg' />
          <Input name="description" label="Description" value={editDescription.value} {...editDescription.bindings} fullWidth bordered size='lg' />
        </Modal.Body>
        <Modal.Footer>
          <Button auto bordered color={'error'} onPress={() => {setShowEditModal(false)}}>Cancel</Button>
          <Button auto bordered color={'success'} onPress={handleEditSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <Card headerLined>
        <Card.Header><Text h3>Talkgroups</Text></Card.Header>
        <Card.Body>
          <Table lined compact striped selectionMode="none">
            <Table.Header columns={keyColumns}>
              {(column) => (
                <Table.Column width={column.width} align={column.key === "action" ? "center" : "start"} key={column.key}>{column.label}</Table.Column>
              )}
            </Table.Header>
            <Table.Body items={list.items} loadingState={list.loadingState} onLoadMore={list.loadMore}>
              {(item) => (
                <Table.Row key={item.talkgroup}>
                  {(key) => renderTableCell(item, key)}
                </Table.Row>
              )}
            </Table.Body>
            <Table.Pagination 
              rowsPerPage={count} 
              total={pages}
              onPageChange={(page) => {
                if (page >= list.items.length / count) {list.loadMore()}
              }}
            />
          </Table>
        </Card.Body>
      </Card>
    </>
  )

}