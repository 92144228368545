import React, { useEffect, useState } from 'react';
import client from '../utils/feathers';
import { Text, Card, Loading } from '@nextui-org/react';
import AudioPlayer from 'react-h5-audio-player';
import '../rh5ap.scss';

import config from '../config.json';

export default function CallPlayer(props) {
  const call = props.call;
  const onAudioEnded = props.onAudioEnded || (() => {});
  const audioStarted = props.onAudioStarted || (() => {});

  const [callInfo, setCallInfo] = useState();
  const [callLoaded, setCallLoaded] = useState(false);
  const [lastCall, setLastCall] = useState();

  useEffect(() => {
    
      setLastCall(call);
      getCall(call)

      async function getCall(id) {
        try {
          const ret = await client.service('calls').get(id);
          setCallInfo(ret);
          setCallLoaded(true)
          waitForTranscription();
        } catch (err) {
          console.log(err);
          return;
        }
      }
    
    return() => {
      client.service('transcription').removeAllListeners();
    }
  }, [call])

  async function waitForTranscription() {
    if (callInfo.transcriptionLoaded) {
      return;
    }

    client.service('transcription').on('created', (data) => {
      console.log(data);
      if (Number(data.call_id) === Number(call)) {
        var call = callInfo;
        call.transcriptionInfo = data;
        call.transcriptionLoaded = true;
        if (data.text) { setCallInfo(call)}
        client.service('transcription').removeListener('created');
      }
    })
  }
  
  if (!callLoaded) {
    return (
      <Card>
        <Card.Header css={{paddingBottom: "5px", marginBottom: "5px" }}>
          <Text h3>Call Info</Text>
        </Card.Header>
        <Card.Divider />
        <Card.Body css={{ paddingTop: "5px", marginTop: "5px"}}>
          <Text b>Please select a call.</Text>
        </Card.Body>
      </Card>
    )
  }

  return (
    <Card>
      <Card.Header css={{ paddingBottom: "5px", marginBottom: "5px" }}>
        <Text h3>Call Info</Text>
      </Card.Header>
      <Card.Divider />
      <Card.Body css={{ paddingTop: "5px", marginTop: "5px"}}>
        <Text>Call ID: {call}</Text>
        <Text>Talkgroup: {(callInfo.talkgroupInfo.tag.length === 0) ? callInfo.talkgroup : callInfo.talkgroupInfo.tag} {(callInfo.talkgroupInfo.description.length === 0) ? <React.Fragment /> : "- " + callInfo.talkgroupInfo.description }</Text>
        <Text>Radio IDs: </Text>
        {callInfo.srclist.map((item,i) => { 
          return (
              <Text css={{display: 'inline'}}>
                {item.src}&nbsp;
              </Text>
          )})
        }
        <Text>Frequency: {callInfo.freq / 1000000} MHz</Text>
        {(callInfo.transcriptionLoaded) ? <Text><b>Transcription:</b> <Text size={'$md'}>{callInfo.transcriptionInfo.text}</Text></Text> : <Text><b>Transcription:</b> <Loading size='sm' type='points' /></Text>}
        <AudioPlayer 
            autoPlay 
            showJumpControls={false} 
            customAdditionalControls={[]} 
            customVolumeControls={[]} 
            layout={'horizontal'} 
            src={'https://' + config.oink_media_prefix + '.' + config.oink_base_url + callInfo.filename} 
            onEnded={onAudioEnded}  
            onPlay={audioStarted}
          />
      </Card.Body>
    </Card>
  )


}