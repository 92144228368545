import React, { useEffect, useState } from "react";
import { NextUIProvider, Navbar, Text, Container, createTheme, Switch, Loading, Modal, User, Dropdown } from "@nextui-org/react";
import Home from "./Pages/Home.js";
import Calls from "./Pages/Calls.js"
import RadioIDs from "./Pages/RadioIDs.js";
import RadioStatus from "./Pages/RadioStatus.js";
import Transcribe from "./Pages/Transcribe.js";
import Login from "./Pages/Login.js";
import client from "./utils/feathers.js";
import APIKeys from "./Pages/ApiKeys.js";
import { UserProvider } from "./UserContext.js";
import Talkgroups from "./Pages/Talkgroups.js";
import { Layout } from "./Components/Layout.js";
import { BrowserRouter, Routes, Route, useLocation, Outlet, redirect, useNavigate } from 'react-router-dom';
import UserSettings from "./Pages/UserSettings.js";
import { MoonIcon, SunIcon } from "./Components/Icons.js";

const App = () => {
  const [dark, setDark] = useState(true);
  const [user, setUser] = useState(null);

  const darkTheme = createTheme({
    type: 'dark',
  });

  const lightTheme = createTheme({
    type: 'light',
  });

  const [showLogin, setShowLogin] = useState(true);
  const [loggedIn, setLoggedIn] = useState(undefined);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const auth = await client.reAuthenticate();
      setUser(auth.user);
      setLoggedIn(true);
      setShowLogin(false);
    }
  
    checkLoggedIn().catch((error) => {
      client.logout().then(() => {
        setShowLogin(true);
        setLoggedIn(false);
        setUser(null);
      })
    });

    document.body.style.overflow = "auto";
  }, []);

  const GenerateUserDropdown = () => {
    var roleName = "";
    if (user.role === 1) {
      roleName = 'Administrator';
    } else if (user.role === 2) {
      roleName = 'Manager';
    } else if (user.role === 3) {
      roleName = 'Developer';
    } else if (user.role === 4) {
      roleName = 'User';
    }

    const navigate = useNavigate();
    
    const handleUserMenuClick = (key) => {
      switch (key) {
        case "logout":
          client.logout().then(() => {
            setUser(null); 
            setShowLogin(true)
            navigate('/');
          })
          break;

        case "usersettings":
          navigate('/usersettings');
          break;
  
        case "apikeys":
          navigate('/apikeys')
          break;
      
        default:
          break;
      }
    }
    
    const location = useLocation();

    return (
      <Dropdown placement="bottom-left">
        <Dropdown.Trigger>
          <User bordered as='button' size='md' color='primary' squared text={user.email.charAt(0).toUpperCase()} name={user.email} description={roleName} />
        </Dropdown.Trigger>
        <Dropdown.Menu color="primary" aria-label="User Actions" onAction={(key) => {handleUserMenuClick(key)}}>
          <Dropdown.Item key="profile" css={{ height: "$18" }}>
            <Text b color='inherit' css={{ d: "flex" }}>Signed in as {user.email}</Text>
          </Dropdown.Item>
          <Dropdown.Item key="usersettings" withDivider>My Settings</Dropdown.Item>
          <Dropdown.Item key="apikeys" withDivider>API Keys</Dropdown.Item>
          {(user.role === 1) && <Dropdown.Item key="sitesettings" withDivider color="success">Site Settings</Dropdown.Item>}
          <Dropdown.Item key="logout" withDivider color="error">Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const GenLayout = ({children}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleNavClick = (e) => {
      e.preventDefault();
      // there *HAS* to be a better way, lol... But this works  ¯\_(ツ)_/¯
      navigate(e.target.attributes.href.value)
    }

    return (
      <>
      <Navbar isBordered variant={"sticky"}>
        <Navbar.Brand>
          <Text color="inherit" size={40} weight="semibold" css={{textGradient: "45deg, $yellow600 0%, $green600 90%",}}>
            Oink
          </Text>
          <Text color="inherit" size={40} weight="black" css={{textGradient: "45deg, $cyan700 -5%, $pink600 90%",}}>
            DB&nbsp;
          </Text>
        </Navbar.Brand>
        <Navbar.Content variant="underline">
          <Navbar.Link href="/" onClick={handleNavClick} isActive={(pathname === "/")}>Home</Navbar.Link>
          <Navbar.Link href="/calls" onClick={handleNavClick} isActive={(pathname === "/calls")}>Calls</Navbar.Link>
          <Navbar.Link href="/radio_ids" onClick={handleNavClick} isActive={(pathname === "/radio_ids")}>Radio IDs</Navbar.Link>
          <Navbar.Link href="/talkgroups" onClick={handleNavClick} isActive={(pathname === "/talkgroups")}>Talkgroups</Navbar.Link>
          <Navbar.Link href="/radio_status" onClick={handleNavClick} isActive={(pathname === "/radio_status")}>Radio Status</Navbar.Link>
          <Navbar.Link href="/transcriptverify" onClick={handleNavClick} isActive={(pathname === "/transcriptverify")}>Verify Transcripts</Navbar.Link>
          {(loggedIn) && <GenerateUserDropdown />}
        </Navbar.Content>
        <Navbar.Content>
          <Switch size="xs" checked={dark} iconOn={<MoonIcon filled />} iconOff={<SunIcon filled />} onChange={() => {setDark(!dark)}} />
        </Navbar.Content>
      </Navbar>
      <Container responsive>
        <br />
        <Outlet />
      </Container>
      </>
    )
  }

  if (loggedIn === undefined) {
    return (
      <UserProvider value={{ user: user, setUser: setUser }}>
        <NextUIProvider theme={(dark) ? darkTheme : lightTheme}>
          
        </NextUIProvider>
      </UserProvider>
    )
  }

  if (showLogin) {
    return (
      <UserProvider value={{ user: user, setUser: setUser }}>
        <NextUIProvider theme={(dark) ? darkTheme : lightTheme}>
          <Layout>
            <Login onLoggedIn={(user) => {setShowLogin(false); setUser(user); setLoggedIn(true);}} />
          </Layout>
        </NextUIProvider>
      </UserProvider>
    )
  }

  return (
    <UserProvider value={{ user: user, setUser: setUser }}>
      <NextUIProvider theme={(dark) ? darkTheme : lightTheme} disableBaseline>
        <Layout>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<GenLayout />}>
                <Route index element={<Home />} />
                <Route path='calls' element={<Calls />} />
                <Route path='radio_ids' element={<RadioIDs />} />
                <Route path='talkgroups' element={<Talkgroups />} />
                <Route path='radio_status' element={<RadioStatus />} />
                <Route path='transcriptverify' element={<Transcribe />} />
                <Route path='apikeys' element={<APIKeys />} />
                <Route path='login' element={<Login />} />
                <Route path='usersettings' element={<UserSettings />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Layout>
      </NextUIProvider>
    </UserProvider>
  );
  
}

export default App;
