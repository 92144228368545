import React, {Component} from 'react';
import client from '../utils/feathers';
import { Text, Card, Button, Table, Textarea, Loading, Container, Modal, styled, Row, Col, Tooltip } from '@nextui-org/react';
import UserContext from '../UserContext';
import { DeleteIcon, IconButton, LockIcon } from '../Components/Icons';

var services = {};

services.apiKeys = client.service('keys');

export default class APIKeys extends Component {
  static contextType = UserContext;

  constructor (props) {
    super(props);

    const keyColumns = [
      { key: "id", label: "Key ID"},
      { key: "description", label: "Key Description"},
      { key: "active", label: "Active" },
      { key: "action", label: "Action" }
    ];

    this.state = {
      keysLoaded: false,
      keyList: [],
      keyColumns: keyColumns,
      generatorVisible: false,
      generatorDescription: "",
      generatorWaiting: false,
      generatedKey: "",
      showKey: false,
      errorMessage: null,
    };

    this.handleGenerateKey = this.handleGenerateKey.bind(this);
    this.handleGeneratorClose = this.handleGeneratorClose.bind(this);
    this.grabUsersKeys = this.grabUsersKeys.bind(this);
    this.renderTableCell = this.renderTableCell.bind(this);
    this.handleKeyAction = this.handleKeyAction.bind(this);
  }

  componentDidMount() {
    document.title = "OinkDB: API Keys"
    this.grabUsersKeys();
  }

  grabUsersKeys() {
    services.apiKeys.find({
      query: {
        $limit: 100,
        $sort: {
          id: 1
        },
        userid: this.context.user.id
      }
    }).then((data) => {
      this.setState({keyList: data.data});
    })
  }

  handleGeneratorClose() {
    this.setState({
      generatorVisible: false, 
      generatorDescription: "", 
      generatorWaiting: false, 
      generatedKey: "", 
      showKey: false,
      errorMessage: null,
    });
  }

  handleGenerateKey() {
    this.setState({generatorWaiting: true});
    services.apiKeys.create({description: this.state.generatorDescription, active: true}).then((data) => {
      this.setState({
        generatedKey: data.key,
        generatorWaiting: false,
        showKey: true,
        errorMessage: null,
      });
      this.grabUsersKeys();
    }).catch((error) => {
      console.log(error.message)
      if (error.message === "validation failed") {
        this.setState({errorMessage: "ERROR: Please give a description!", generatorWaiting: false});
      } else {
        this.setState({errorMessage: error.message, generatorWaiting: false});
      }
    })
  }

  renderGeneratorButton() {
    if (this.state.generatorWaiting) {
      return <Button disabled auto color='warning'><Loading type='points' color='success' size='sm' /></Button>
    } else if (this.state.showKey) {
      return <Button color='error' auto onPress={this.handleGeneratorClose}>Close</Button>
    } else {
      return <Button color='success' auto onPress={this.handleGenerateKey}>Generate</Button>
    }
  }

  handleKeyAction(item, action) {
    if (action === 'disable') {
      services.apiKeys.patch(item.id, {active: !item.active}).then((result) => {
        console.log(result);
        this.grabUsersKeys();
      })
    } else if (action === 'delete') {
      services.apiKeys.remove(item.id).then((result) => {
        console.log(result);
        this.grabUsersKeys();
      })
    }
  }

  renderTableCell(item, key) {
    if (key === 'active') {
      if (item[key]) {
        return (<Text b color='success'>Active</Text>)
      } else {
        return (<Text b color='error'>Disabled</Text>)
      }
    } else if (key === 'action') {
      var ableAction = "";
      var ableColor = "";
      var lockColor = "";

      if (item.active) {
        ableAction = "Disable";
        ableColor = "warning";
        lockColor = "#F5A524";
      } else {
        ableAction = "Enable";
        ableColor = "success";
        lockColor = "#17C964";
      }
      return (
        <Row justify='center' align='center'>
          <Col css={{d:'flex'}}>
            <Tooltip content={ableAction} color={ableColor}>
              <IconButton onClick={() => this.handleKeyAction(item, 'disable')}>
                <LockIcon size={20} fill={lockColor} />
              </IconButton>
            </Tooltip>
          </Col>
          <Col css={{d:'flex'}}>
            <Tooltip content='Delete' color='error'>
              <IconButton onClick={() => this.handleKeyAction(item, 'delete')}>
                <DeleteIcon size={20} fill="#F31260" />
              </IconButton>
            </Tooltip>
          </Col>
        </Row>
      )
    } else {
      return item[key]
    }
  }

  render() {
    return (
      <Container direction='column' gap={0}>
        <Modal closeButton aria-labelledby='modal-title' width='700px' open={this.state.generatorVisible} onClose={this.handleGeneratorClose}>
          <Modal.Header id='modal-title'>
            {(this.state.showKey) ? 
              <Text b size={22} color='success'>Success!</Text> : 
              <Text b size={22}>Generate New API Key</Text>
            }
          </Modal.Header>
          <Modal.Body css={{justifyContent: 'center', textAlign: 'center'}}>
            {this.state.errorMessage != null && 
              <Text color='error' b>{this.state.errorMessage}</Text>
            }
            {this.state.showKey === true && 
              <React.Fragment>
                <Text>
                  <Text color='success'>This is your new API key.</Text>
                  <Text color='success'>Please make sure you save this in a safe place, since this is just as powerful as your password!</Text>
                  <Text color='success' b size="$xl">After you close this, your key will not be visible again.</Text>
                </Text>
                <Textarea fullWidth bordered readOnly status='success' initialValue={this.state.generatedKey} />
              </React.Fragment>
            }
            <Textarea 
              label='Description' 
              placeholder='Describe what your key will be used for.' 
              fullWidth 
              bordered
              required
              readOnly={(this.state.generatorWaiting || this.state.showKey)} 
              onChange={(e) => this.setState({generatorDescription: e.target.value})}  
            />
          </Modal.Body>
          <Modal.Footer>
            {this.renderGeneratorButton()}
          </Modal.Footer>
        </Modal>
        <Card>
          <Card.Header><Text b size='$2xl'>API Keys</Text></Card.Header>
          <Card.Body>
            <Table selectionMode='none' lined headerLined compact striped>
              <Table.Header columns={this.state.keyColumns}>
                {(column) => (
                  <Table.Column width={column.key === "action" ? '50' : '-'} align={column.key === "action" ? "center" : "start"} key={column.key}>{column.label}</Table.Column>
                )}
              </Table.Header>
              <Table.Body items={this.state.keyList}>
                {(item) => (
                  <Table.Row key={item.key}>
                    {(columnKey) => (<Table.Cell>{this.renderTableCell(item, columnKey)}</Table.Cell>)}
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card.Body>
          <Card.Footer css={{justifyContent: 'right'}}>
            <Button auto color={'success'} size='sm' onClick={() => {this.setState({generatorVisible: true})}}>Generate New Key</Button>
          </Card.Footer>
        </Card>
      </Container>
    )
  }
}


