import React, { Component } from 'react';
import client from '../utils/feathers';
import { Text, Card, Grid, Badge, Avatar, Tooltip } from '@nextui-org/react';

var radioService = client.service('radiostatus');
var talkgroupService = client.service('talkgroups');

export default class RadioStatus extends Component {
  constructor (props) {
    super(props);

    this.state = {
      statusList: [],
      sortedStatus: [],
      talkgroups: [],
    }
  }

  async getAllStatus() {
    var pulled = 0;
    var total = 1;
    var arr = [];
    var sorted = [];
    
    while (pulled < total) {
      var ret = await radioService.find({ query: {$skip: pulled}});
      arr.push(...ret.data);
      total = ret.total;
      pulled += ret.data.length;
    }
    arr.forEach(record => {
      if (!(record.talkgroup in sorted)) {
        sorted[record.talkgroup] = [];
      }
      if (record.id in sorted[record.talkgroup]) {
        if (sorted[record.talkgroup][record.id].power !== record.power) {
          sorted[record.talkgroup][record.id] = record;
        }
      } else {
        sorted[record.talkgroup][record.id] = record;
      }
    });
    // sorted.splice(0, 1);
    sorted[0] = [];
    this.setState({statusList: arr});
    this.setState({sortedStatus: sorted});
  }

  async getAllTalkgroups() {
    var pulled = 0;
    var total = 1;
    var arr = [];

    while (pulled < total) {
      var ret = await talkgroupService.find({ query: {$skip: pulled}});
      arr.push(...ret.data);
      total = ret.total;
      pulled += ret.data.length;
    }
    this.setState({talkgroups: arr});
  }

  componentDidMount() {
    document.title = "OinkDB: Radio Status";
    // get all of the radio status info
    if (this.state.statusList.length === 0) {
      this.getAllStatus();
    }

    // get all talkgroups
    if (this.state.talkgroups.length === 0) {
      this.getAllTalkgroups();
    }

    radioService.on('created', (data) => {
      // var status = this.state.statusList;
      // var index = status.findIndex(x => x.id === data.id);
      // this.sortRecord(data);
      
      
      // if (index >= 0) {
      //   if (status[index].power !== data.power || status[index].talkgroup !== data.talkgroup) {
      //     status[index] = data;
      //     this.setState({statusList: status});
      //     console.log("update")
      //   }
      // } else {
      //   status.push(data);
      //   this.setState({statusList: status});
      // }
      
    })
  }

  sortRecord(record) {
    var state = this.state.sortedStatus;
    var unsorted = this.state.statusList;

    if (!(record.talkgroup in state)) {
      state[record.talkgroup] = [];
    }
    if (record.id in state[record.talkgroup]) {
      if (state[record.talkgroup][record.id].power !== record.power) {
        state[record.talkgroup][record.id] = record;
        this.setState({sortedStatus: state});
      }
    } else {
      state[record.talkgroup][record.id] = record;
      this.setState({sortedStatus: state});
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <Grid.Container gap={1}>
          {/* {this.state.sortedStatus.map((talkgroup, index) => (
            <Grid xs={3}>
              <Card>
                <Card.Header><Text h3>TG: {index}</Text></Card.Header>
                <Card.Body>
                  <Text>Radios: {talkgroup.length}</Text>
                {talkgroup.map((radio) => (
                  <Grid>
                    <Tooltip content={(<><Text>ID: {radio.id}</Text><Text>Talkgroup: {radio.talkgroup}</Text></>)}>
                      <Avatar key={radio.id} size={'xs'} color={(radio.power === 'on') ? 'success' : 'error'} />
                    </Tooltip>
                    <Text>{radio.id}</Text>
                  </Grid>
                ))}
                </Card.Body>
              </Card>
            </Grid>
          ))} */}
        </Grid.Container>
      </React.Fragment>
    )
  }
}

