import React, {Component} from 'react';
import client from '../utils/feathers';
import { Text, Card, Button, Grid, Textarea, Loading } from '@nextui-org/react';
import AudioPlayer from 'react-h5-audio-player';
import '../rh5ap.scss';
import axios from 'axios';
import config from '../config.json';

var services = {};

services.calls = client.service('calls');
services.transcription = client.service('transcription');

export default class TranscriptionCard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      call: { id: 0, call: {}, transcription: {} },
      callLoaded: false,
      transcriptionWaiting: true,
      maxCall: props.maxCall
    }

    this.onTextChange = this.onTextChange.bind(this);
    this.onButtonPress = this.onButtonPress.bind(this);
    this.getNewCall = this.getNewCall.bind(this);
  }

  componentDidMount() {
    this.getCall(Math.round(Math.random() * this.state.maxCall));
    services.transcription.on('created', (trx) => {
      if (this.state.call.id === Number(trx.call_id)) {
        var call = this.state.call;
        call.transcription = trx;
        this.setState({call: call, transcriptionWaiting: false});
        // services.transcription.removeListener('created');
        console.log("Got Transcription")
      }
    })
  }

  componentWillUnmount() {
    services.transcription.removeListener('created');
  }

  getCall(id) {
    try {
      services.calls.find({query: { id: id }}).then((c) => {
        if (c.total > 0) {
          var call = this.state.call;
          call.call = c.data[0];
          call.id = id;
          
          services.transcription.find({query: {call_id: id}}).then((trx) => {
            if (trx.total === 0) {
              // request new transcription
              this.setState({transcriptionWaiting: true});
              const res = axios.post('https://' + config.oink_fast_stt_prefix + '.' + config.oink_base_url + '/', {file: call.call.filename, callid: id});
            } else {
              call.transcription = trx.data[0];
              console.log(trx.data[0]);
              this.setState({transcriptionWaiting: false});
            }
          })

          this.setState({call: call, callLoaded: true});

        } else {
          this.getCall(Math.round(Math.random() * this.state.maxCall));
        }
      })
    } catch (err) {
      console.log(err);
      this.getCall(Math.round(Math.random() * this.state.maxCall));
    }
  }

  onTextChange(e) {
    var call = this.state.call;
    call.transcription.text = e.target.value;
    this.setState({call: call});
  }

  onButtonPress(e) {
    var call = this.state.call;
    if (e.target.name === "accept") {
      call.transcription.rating = "H";
      console.log("Accepted");
    } else {
      call.transcription.rating = "R";
      console.log("Rejected!");
    }
    this.setState({call: call});
    call.transcription.id = Number(call.transcription.id);
    call.transcription.call_id = Number(call.transcription.call_id)
    services.transcription.patch(call.transcription.id, call.transcription).then((r) => {
      console.log(r);
      this.setState({callLoaded: false, transcriptionWaiting: true});
      this.getCall(Math.round(Math.random() * this.state.maxCall));
    });
  }

  getNewCall() {
    this.setState({callLoaded: false, transcriptionWaiting: true});
    this.getCall(Math.round(Math.random() * this.state.maxCall));
  }

  render() {
    if (this.state.callLoaded) {
      return (
        <React.Fragment>
          <Card>
            <Card.Header><Text h3>Call ID: {this.state.call.id}</Text></Card.Header>
            <Card.Body>
              <AudioPlayer
                showJumpControls={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                layout={'horizontal'}
                autoPlayAfterSrcChange={false}
                src={'https://' + config.oink_media_prefix + '.' + config.oink_base_url + this.state.call.call.filename}
              />
              <Text h6>Transcription: </Text>
              {(this.state.transcriptionWaiting === false) ? (
                <Textarea bordered onChange={this.onTextChange} initialValue={this.state.call.transcription.text} />
              ) : (
                <Loading />
              )}
            </Card.Body>
            <Card.Footer>
              <Grid.Container gap={1} justify='center'>
                <Grid>
                  <Button name='reject' ghost color={"error"} onClick={this.onButtonPress}>Reject</Button>
                </Grid>
                <Grid>
                  <Button name='accept' ghost color={"success"} onClick={this.onButtonPress}>Accept</Button>
                </Grid>
                <Grid>
                  <Button name="skip" ghost color={"warning"} onClick={this.getNewCall}>Skip</Button>
                </Grid>
              </Grid.Container>
            </Card.Footer>
          </Card>

        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Card>
            <Card.Body>
              <Loading />
            </Card.Body>
          </Card>
        </React.Fragment>
      )
    }
    
  }
}