import { styled } from "@nextui-org/react";
import React from "react";

export const Box = styled("div", {
  boxSizing: "border-box"
});

export const Layout = ({children}) => {
  return (
    <Box css={{maxW: "100%"}}>
      {children}
    </Box>
  )
}