import React, { Component } from 'react';
import client from '../utils/feathers';
import { Text, Card, Grid, Table, Switch, Spacer, Input, Container, Col, Row, Loading, Button } from '@nextui-org/react';
import AudioPlayer from 'react-h5-audio-player';
import '../rh5ap.scss';

import config from '../config.json';

var services = {};
services.calls = client.service('calls');
services.talkgroups = client.service('talkgroups');
services.srclist = client.service('srclist');
services.radioids = client.service('radio-ids');
services.transcription = client.service('transcription');

export default class Calls extends Component {

  constructor (props) {
    super(props);

    var callColumns = [
      { key: "id", label: "Call ID" },
      { key: "call_length", label: "Length" },
      { key: "talkgroupInfo.tag", label: "Talkgroup" },
      { key: "start_time", label: "Call Time" },
    ];

    this.state = {
      useFilter: true,
      useTimeFilter: false,
      timeDateFilter: {startTime: '', startDate: '', endTime: '', endDate:''},
      useTalkgroupFilter: false,
      tgFilter: "",
      useRadioIDFilter: false,
      callColumns: callColumns,
      callList: [],
      callInfo: null,
      loadedCall: false,
      autoPlay: true,
      autoPlayTowardsNew: true,
      selectedRow: null,
      mediaPlaying: false,
      transcriptionLoaded: false,
      callTranscription: "",
      filters: { time: { start: null, end: null }, tg: [], rid: [] },
      filterCalls: false,
    }

    this.renderCallInfo = this.renderCallInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.audioEnded = this.audioEnded.bind(this);
    this.checkStartPlaying = this.checkStartPlaying.bind(this);
    this.getCall = this.getCall.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.getInitialCalls = this.getInitialCalls.bind(this);
  }

  componentDidMount() {
    document.title = "OinkDB: Calls";
    services.calls.on('created', (data) => {
      if (!this.state.filterCalls) {
        this.getCall(data.id, true);
      } else {
        if (this.state.useTalkgroupFilter) {
          var tgs = this.state.tgFilter.split(',');
          console.log(data, tgs);
          if (!tgs.includes(data.talkgroup.toString())) {
            return;
          }
        }

        if (this.state.useTimeFilter) {
          // check if call is within the timeframe requested
          var callTime = new Date(data.start_time);
          if (callTime > new Date(this.state.filters.time.end) || callTime < new Date(this.state.filters.time.start)) {
            return;
          }
        }

        // we should be good to go, call matches filters
        this.getCall(data.id, true);

      }
    })

    services.transcription.on('created', (data) => {
      if (Number(data.call_id) === Number(this.state.callInfo.id)) {
        var call = this.state.callInfo;
        call.transcriptionInfo = data;
        call.transcriptionLoaded = true;
        if (data.text) {this.setState({callInfo: call})}
      } else {
        const match = this.state.callList.map((item) => {return Number(item.id);}).indexOf(Number(data.call_id));
        var calls = this.state.callList;
        calls[match].transcriptionLoaded = true;
        calls[match].transcriptionInfo = data;
        this.setState({callList: calls});  
      }
    })

    this.getInitialCalls();
    // document.body.style.overflow = "auto";
  }

  getInitialCalls() {
    var queryObj = {};
    queryObj.$limit = 50;
    queryObj.$sort = { start_time: -1 };

    if (this.state.filterCalls) {
      if (this.state.useTalkgroupFilter && this.state.filters.tg.length > 0) {
        queryObj.talkgroup = { $in: this.state.filters.tg }
      }

      if (this.state.useTimeFilter) {
        queryObj.start_time = { $gt: this.state.filters.time.start, $lt: this.state.filters.time.end };
      }
    }

    services.calls.find({
      query: queryObj
    }).then((data) => {
      this.setState({callList: data.data});
    })
  }

  getCall(callID, delay = false) {
    if (delay) {
      setTimeout(() => {
        this.getCall(callID, false);
      }, 500);
    } else {
      services.calls.get(callID).then((call) => {
        var calls = this.state.callList;
        calls.unshift(call);
        this.setState({callList: calls});
        this.checkStartPlaying(callID);
      })
    }
  }

  handleChange(e,d) {
    var target = e.nativeEvent.target;
    switch (target.name) {
      case 'autoPlay':
        this.setState({autoPlay: e.target.checked})
        break;

      case 'autoPlayTowardsNew':
        this.setState({autoPlayTowardsNew: e.target.checked});
        break;

      case 'useTalkgroupFilter':
        this.setState({useTalkgroupFilter: e.target.checked});
        break;

      case 'useFilter':
        this.setState({useFilter: e.target.checked});
        if (!e.target.checked) { this.setState({filterCalls: false})};
        break;

      case 'useRadioIDFilter':
        this.setState({useRadioIDFilter: e.target.checked});
        break;

      case 'useTimeFilter':
        this.setState({useTimeFilter: e.target.checked});
        break;

      case 'tgFilter':
        this.setState({tgFilter: e.target.value});
        break;

      case 'startTime':
        var td = this.state.timeDateFilter;
        td.startTime = e.target.value;
        this.setState({timeDateFilter: td});
        console.log(e.target.value)
        break;

      case 'startDate':
        var td = this.state.timeDateFilter;
        td.startDate = e.target.value;
        this.setState({timeDateFilter: td});
        console.log(e.target.value)
        break;

      case 'endTime':
        var td = this.state.timeDateFilter;
        td.endTime = e.target.value;
        this.setState({timeDateFilter: td});
        console.log(e.target.value)
        break;

      case 'endDate':
        var td = this.state.timeDateFilter;
        td.endDate = e.target.value;
        this.setState({timeDateFilter: td});
        console.log(e.target.value)
        break;

      default:
        break;
    }
  }

  async applyFilters() {
    var filters = {time: { start: null, end: null }, tg: [], rid: [] };

    // assemble talkgroup filter
    if (this.state.useTalkgroupFilter) {
      var tgs = this.state.tgFilter.split(',');
      tgs.forEach(tg => {
        filters.tg.push(Number(tg));
      });
    }

    // assemble radio id filter
    if (this.state.useRadioIDFilter) {
      var rids = this.state.radioids.split(',');
      rids.forEach(rid => {
        filters.rid.push(Number(rid));
      })
    }

    // assemble time/date filter
    if (this.state.useTimeFilter) {
      // start
      var startTS = Date.now();
      var startString = '';
      if (this.state.timeDateFilter.startDate !== '') {
        startString += this.state.timeDateFilter.startDate + ' ';
      } else {
        startString += startTS.getFullYear() + '-' + startTS.getMonth() + '-' + startTS.getDate() + ' ';
      }

      if (this.state.timeDateFilter.startTime !== '') {
        startString += this.state.timeDateFilter.startTime;
      } else {
        startString += '00:00:00';
      }

      // end
      var endTS = Date.now();
      var endString = '';
      if (this.state.timeDateFilter.endDate !== '') {
        endString += this.state.timeDateFilter.endDate + ' ';
      } else {
        endString += endTS.getFullYear() + '-' + endTS.getMonth() + '-' + endTS.getDate() + ' ';
      }

      if (this.state.timeDateFilter.endTime !== '') {
        endString += this.state.timeDateFilter.endTime;
      } else {
        endString += '23:59:59';
      }
      
      startTS = new Date(startString);
      endTS = new Date(endString);

      filters.time.start = startTS;
      filters.time.end = endTS;
    }

    await this.setState({ filters: filters, filterCalls: true, callList: [] })
    this.getInitialCalls();
  }

  componentWillUnmount() {
    services.calls.removeListener('created');
    services.transcription.removeListener('created');
  }

  tableItemSelection(selection) {
    this.setState({loadedCall: false, selectedRow: selection, transcriptionLoaded: false});
    services.calls.get(selection.anchorKey).then((data) => {
      this.setState({callInfo: data, loadedCall: true});
    })
  }

  checkStartPlaying(callID) {
    if (this.state.autoPlay && this.state.autoPlayTowardsNew && !this.state.mediaPlaying) {
      var newSelection = new Set();
      newSelection.add(callID);
      newSelection.anchorKey = callID;
      newSelection.currentKey = callID;
      this.setState({selectedRow: newSelection});
      this.tableItemSelection(newSelection);
    }
  }

  audioEnded() {
    if (this.state.autoPlay) {
      var currentIndex = this.state.callList.findIndex(x => x.id === this.state.callInfo.id);
      var newIndex;
      if (this.state.autoPlayTowardsNew) {
        newIndex = currentIndex - 1;
      } else {
        newIndex = currentIndex + 1;
      }
      if (newIndex >= 0) {
        var newSelection = new Set();
        newSelection.add(this.state.callList[newIndex].id)
        newSelection.anchorKey = this.state.callList[newIndex].id;
        newSelection.currentKey = this.state.callList[newIndex].id;
        this.setState({selectedRow: newSelection})
        this.tableItemSelection(newSelection);
      } else {
        this.setState({mediaPlaying: false});
      }
    }
  }

  renderCallInfo = () => {
    if (this.state.loadedCall) {
      const callInfo = this.state.callInfo;
      return (
        <React.Fragment>
          <Text><b>Call ID:</b> {callInfo.id}</Text>
          <Text><b>Talkgroup:</b> {(callInfo.talkgroupInfo.tag.length === 0) ? callInfo.talkgroup : callInfo.talkgroupInfo.tag} {(callInfo.talkgroupInfo.description.length === 0) ? <React.Fragment /> : "- " + callInfo.talkgroupInfo.description }</Text>
          <Text><b>Radio IDs:</b> {callInfo.srclist.map((item,i) => { 
            return (
                <Text css={{display: 'inline'}}>
                  {item.src}&nbsp;
                </Text>
            )})}</Text>
          <Text><b>Frequency:</b> {callInfo.freq / 1000000} MHz</Text>
          {(callInfo.transcriptionLoaded) ? <Text><b>Transcription:</b> <Text size={'$md'}>{callInfo.transcriptionInfo.text}</Text></Text> : <Text><b>Transcription:</b> <Loading size='sm' type='points' /></Text>}
          <AudioPlayer 
            autoPlay 
            showJumpControls={false} 
            customAdditionalControls={[]} 
            customVolumeControls={[]} 
            layout={'horizontal'} 
            src={'https://' + config.oink_media_prefix + '.' + config.oink_base_url + callInfo.filename} 
            onEnded={this.audioEnded}  
            onPlay={(e) => {this.setState({mediaPlaying: true})}}
          />
        </React.Fragment>
      )
    }
  }

  renderTableCell = (item, columnKey) => {
    if (columnKey === 'start_time') {
      return (<Table.Cell>{new Date(item[columnKey]).toLocaleString('en-US')}</Table.Cell>)
    } else if (columnKey === 'talkgroupInfo.tag') {
      if (item['talkgroupInfo'].tag.length === 0) {
        return (<Table.Cell>{item['talkgroupInfo'].talkgroup}</Table.Cell>)
      } else {
        return (<Table.Cell>{item['talkgroupInfo'].tag}</Table.Cell>)
      }
    } else {
      return (<Table.Cell>{item[columnKey]}</Table.Cell>)
    }
  }

  render() {
    return(
      <React.Fragment>
        <Container direction='column' gap={0}>
          <Row gap={1}>
            <Col span={12}>
              <Card>
                <Card.Header>Enable Filters:<Spacer /><Switch size="xs" checked={this.state.useFilter} onChange={() => {this.setState({useFilter: !this.state.useFilter})}} /></Card.Header>
                  {(this.state.useFilter) ? 
                    <Card.Body>
                      <Grid.Container gap={1}>
                        <Grid xs={4}>
                          <Card variant='bordered'>
                            <Card.Header>Time and Date: <Switch size="xs" checked={this.state.useTimeFilter} name='useTimeFilter' onChange={this.handleChange} /></Card.Header>
                            {(this.state.useTimeFilter) && (
                              <>
                                <Card.Divider />
                                <Card.Body>
                                  <Grid.Container gap={1}>
                                    <Grid xs={6}><Input width="120px" label="Start Date" type="date" name='startDate' onChange={this.handleChange} underlined /></Grid>
                                    <Grid xs={6}><Input width="120px" label="Start Time" type="time" name='startTime' onChange={this.handleChange} underlined /></Grid>
                                    <Grid xs={6}><Input width="120px" label="End Date" type="date" name='endDate' onChange={this.handleChange} underlined /></Grid>
                                    <Grid xs={6}><Input width="120px" label="End Time" type="time" name='endTime' onChange={this.handleChange} underlined /></Grid>
                                  </Grid.Container>
                                </Card.Body>
                              </>
                            )}
                          </Card>
                        </Grid>
                        <Grid xs={4}>
                          <Card variant='bordered'>
                            <Card.Header>Talkgroup: <Switch size="xs" checked={this.state.useTalkgroupFilter} name='useTalkgroupFilter' onChange={this.handleChange} /></Card.Header>
                            {(this.state.useTalkgroupFilter) && (
                              <>
                                <Card.Divider />
                                <Card.Body>
                                  <Input width="120px" label="Talkgroup" underlined name="tgFilter" onChange={this.handleChange} />
                                </Card.Body>
                              </>
                            )}
                          </Card>
                        </Grid>
                        <Grid xs={4}>
                          <Card variant='bordered'>
                            <Card.Header>Radio ID: <Switch size="xs" checked={this.state.useRadioIDFilter} name='useRadioIDFilter' onChange={this.handleChange} /></Card.Header>
                            {(this.state.useRadioIDFilter) && (
                              <>
                                <Card.Divider />
                                <Card.Body>
                                  <Input width="120px" label="Radio ID" underlined />
                                </Card.Body>
                              </>
                            )}
                          </Card>
                        </Grid>
                        <Grid xs={12} css={{ justifyContent: 'right' }}>
                          <Button size={'sm'} onClick={this.applyFilters}>Apply Filters</Button>
                        </Grid>
                      </Grid.Container>
                    </Card.Body>
                  : ''}
              </Card>
            </Col>
          </Row>
          <Spacer y={1} />
          <Row gap={1}>
            <Col span={8}>
              <Row>
              <Card style={{overflow: 'auto'}}>
                <Card.Header><Text h3>Calls</Text></Card.Header>
                <Card.Body>
                  <Table selectionMode='single' onSelectionChange={(key) => {this.tableItemSelection(key)}} selectedKeys={this.state.selectedRow} lined headerLined compact striped>
                    <Table.Header columns={this.state.callColumns}>
                      {(column) => (
                        <Table.Column key={column.key}>{column.label}</Table.Column>
                      )}
                    </Table.Header>
                    <Table.Body items={this.state.callList}>
                    {(item) => (
                      <Table.Row key={item.key}>
                        {(columnKey) => (this.renderTableCell(item, columnKey))}
                      </Table.Row>
                    )}
                    </Table.Body>
                  </Table>
                </Card.Body>
              </Card>
              </Row>
            </Col>
            <Col span={4} css={{ position: 'sticky', top: '90px'}}>
              <Row>
                <Card>
                  <Card.Header css={{ paddingBottom: "5px", marginBottom: "5px" }}><Text h3>Call Info</Text></Card.Header>
                  <Card.Divider />
                  <Card.Body css={{ paddingTop: "5px", marginTop: "5px" }}><this.renderCallInfo /></Card.Body>
                </Card>
              </Row>
              <Spacer y={1} />
              <Row>
                <Card >
                  <Card.Header><Text h3>Settings</Text></Card.Header>
                  <Card.Divider />
                  <Card.Body>
                    <label>Auto Play: </label> <Switch size={"xs"} name='autoPlay' checked={this.state.autoPlay} onChange={this.handleChange} />
                    <label>Auto Play Newest: <Switch css={{paddingTop: '5px'}} size={"xs"} name='autoPlayTowardsNew' checked={this.state.autoPlayTowardsNew} onChange={this.handleChange} /></label>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}