import React, { useContext, useState } from "react";
import client from "../utils/feathers";
import { Text, Card, Input, Button, useInput, Spacer } from '@nextui-org/react';
import { EditIcon, IconButton } from "../Components/Icons";
import UserContext from "../UserContext";

var services = {};
services.users = client.service('users');

export default function UserSettings() {
  document.title = "OinkDB: User Settings";

  const pass = useInput();
  const passver = useInput();
  const [passError, setPassError] = useState('');
  const [passSuccess, setPassSuccess] = useState('');
  const user = useContext(UserContext);

  async function updatePassword() {
    if (pass.value !== passver.value) {
      setPassError('Passwords do not match.');
      return;
    }

    if (pass.value.length < 8) {
      setPassError('Passwords must be at least 8 characters');
      return;
    }

    var res = await services.users.patch(user.user.id, {password: pass.value});
    setPassSuccess('Password Changed!');
    setPassError('');
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Text h3>User Settings</Text>
        </Card.Header>
        <Card.Body>
          <Text h4>Change Password</Text>
          <Text color='error' visible={(passError !== '') ? 'false' : 'true'}>{passError}</Text>
          <Text color='success' visible={(passSuccess !== '') ? 'false' : 'true'}>{passSuccess}</Text>
          <Input width="300px" type="password" name="pass1" label="New Password" value={pass.value} {...pass.bindings} bordered />
          <Spacer y={1} />
          <Input width="300px" type="password" name="pass2" label="Password Again" value={passver.value} {...passver.bindings} bordered />
          <Spacer y={1} />
          <Button css={{ width: '100px' }} bordered color={'success'} onPress={() => updatePassword()}>Change Password</Button>
        </Card.Body>
      </Card>
    </>
  )
}