import {feathers} from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import config from '../config.json';
import auth from '@feathersjs/authentication-client';

const socket = io();
// const socket = io("https://dev.oinkdb.net");
// socket.disconnect().connect();
const client = feathers();

client.configure(socketio(socket));
client.configure(
  auth({ storage: window.localStorage, storageKey: "feathers-react-jwt"})
);

export default client