import React, {Component} from 'react';
import client from '../utils/feathers';
import { Text, Card, Grid, Button, Container, Textarea, Loading } from '@nextui-org/react';
import AudioPlayer from 'react-h5-audio-player';
import '../rh5ap.scss';
import axios from 'axios';
import TranscriptionCard from '../Components/TranscriptionCard';
import config from '../config.json';


var services = {};

var calls = [];
var trxWatchList = [];
var callsToGrab = 3;

services.calls = client.service('calls');
services.transcription = client.service('transcription');

export default class Transcribe extends Component {
  constructor (props) {
    super(props);
    this.state = {
      calls: [],
      callsLoaded: false,
      totalCalls: 0,
      trxWatchList: []
    }

    this.getTranscriptionFromList = this.getTranscriptionFromList.bind(this);
    this.onButtonPress = this.onButtonPress.bind(this);
    this.onChange = this.onTextChange.bind(this);
  }

  componentDidMount() {
    document.title = "OinkDB: Transcription Verification";
    // get number of calls in db
    services.calls.find({query: { $limit: 1, $sort: { id: -1}}}).then((calls) => {
      // console.log("test")
      this.setState({totalCalls: calls.data[0].id, callsLoaded: true});
      // for (let i = 0; i < callsToGrab; i++) {
        // this.getCall(Math.round(Math.random() * calls.data[0].id));
      // }
    })

    services.transcription.on('created', (trx) => {
        calls = this.state.calls;
        calls.forEach(call => {
          if (call.id == trx.call_id) {
            call.transcription = trx;
            console.log(trx)
          }
        });
        this.setState({calls: calls});
      })
  }

  getCall(id) {
    try {
      services.calls.find({query: {id: id}}).then((call) => {
        if (call.total > 0) {
          calls[id] = {call: {}, transcription: {}, id: id};
          calls[id].call = call.data[0];
          this.setState({calls: calls, callsLoaded: true})
          // check if a transcription already exists
          services.transcription.find({query: { call_id: id }}).then((trx) => {
            if (trx.total === 0) {
              // request new transcription, add it to the watch list
              trxWatchList = this.state.trxWatchList;
              trxWatchList.push(id);
              calls[id]['transcription'] = false;
              this.setState({trxWatchList: trxWatchList, calls: calls});
              const res = axios.post('https://' + config.oink_stt_prefix + '.' + config.oink_base_url, {file: call.data[0].filename, callid: id});
            } else {
              // we already have a transcription, put it in the ready list
              calls = this.state.calls;
              calls[id]['transcription'] = trx.data[0];
              this.setState({calls: calls});
              console.log(trx.data[0])
            }
          })
        } else {
          this.getCall(Math.round(Math.random() * this.state.totalCalls));
        }
      })
    } catch (err) {
      this.getCall(Math.round(Math.random() * this.state.totalCalls));
    }
  }

  getTranscriptionFromList(callid) {
    this.state.transcriptions.forEach(trx => {
      if (trx.call_id === callid) {
        return trx.text;
      }
    });
  }

  componentWillUnmount() {
    services.transcription.removeListener('created');
  }

  onButtonPress(e,call) {
    console.log(e,call);
  }

  onTextChange(e,call) {
    console.log(e.target.value);
    // console.log(e.target.value)
    call.transcription.text = e.target.value;
    calls = this.state.calls;
    calls[call.id].transcription = call.transcription;
    this.setState({calls: calls});
  }

  render() {
    if (this.state.callsLoaded) {
      return (
        <Grid.Container gap={1}>
          {[...Array(6)].map((e, index) => (
            <Grid xs={4} key={index}>
              <TranscriptionCard maxCall={this.state.totalCalls} />
            </Grid>
          ))}
        </Grid.Container>
      )
    } else {
      return (
        <Loading />
      )
    }
  }
}