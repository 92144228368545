import React from 'react';
import client from '../utils/feathers';
import { Text, Card, Input, Table, useAsyncList } from '@nextui-org/react';

var services = {};
services.calls = client.service('calls');
services.radios = client.service('radio-ids');
services.talkgroups = client.service('talkgroups');

export default function RadioIDs() {
  document.title = "OinkDB: Radio IDs";
    var filterValue = "";
    const columns = [
      {
        key: "radio_id",
        label: "Radio ID"
      },
      {
        key: "callsign",
        label: "Callsign",
      },
      {
        key: "notes",
        label: "Notes",
      }
    ]

    async function load({ signal, cursor, filterText }) {
      cursor = cursor || 0;

      var query = {
        $sort: {
          radio_id: 1
        },
        $limit: 20,
        $skip: cursor,
      };

      filterValue = filterText.target.value

      if (filterValue !== "") {      
        // query.push(filter);
        query = {radio_id: {$like: filterValue+"%"},...query}
        // query.$skip = 0;
      }
      console.log(query)
      const res = await services.radios.find(query)
      
      cursor = res.skip + res.limit

      console.log(res, cursor);

      return {
        items: res.data,
        cursor: cursor
      }
    }
    
    const list = useAsyncList({ load });

    // function filterChange(e) {
    //   // list.filterText(e.target.value)
    // }
  
    return(
      <React.Fragment>
        <Card>
          <Card.Header><Text h3>Radio IDs</Text></Card.Header>
          <Card.Body>
            <Input label='Filter' onChange={list.setFilterText} />
          </Card.Body>
        </Card>
          <Table css={{ height: "calc($space$14 * 15)", minWidth: "100%" }}>
            <Table.Header columns={columns}>
              {(column) => (
                <Table.Column key={column.key}>{column.label}</Table.Column>
              )}
            </Table.Header>
            <Table.Body 
              items={list.items}
              loadingState={list.loadingState}
              onLoadMore={list.loadMore}
            >
              {(item) => (
                <Table.Row key={item.id}>
                  {(id) => <Table.Cell>{item[id]}</Table.Cell>}
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </React.Fragment>
    )
  

}