import React, { Component } from 'react';
import client from '../utils/feathers';
import { Text, Input, Button, Modal, Container } from '@nextui-org/react';

export default class Login extends Component {
  constructor (props) {
    super(props);

    this.state = {
      user: "",
      pass: "",
      showHelperText: false,
      helperText: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(e,d) {
    if (e.target.name === 'user') {
      this.setState({user: e.target.value});
    } else if (e.target.name === 'pass') {
      this.setState({pass: e.target.value});
    }
  }

  loggedIn = (data) => this.props.onLoggedIn(data);

  async handleLogin(e) {
    if (!this.state.user || !this.state.pass) {
      this.setState({
        showHelperText: true,
        helperText: "Please enter a username and password"
      })
      return;
    }
    
    const {user, pass} = this.state;

    try {
      const result = await client.authenticate({
        strategy: 'local',
        email: user,
        password: pass
      })
      this.loggedIn(result.user);
    } catch (error) {
      console.error('Authentication error:', error);
      this.setState({showHelperText: true, helperText: error.message})
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") { 
      this.handleLogin();
     }
  }

  render() {
    return(
      <Container>
        <Modal blur aria-labelledby='modal-title' open preventClose>
          <Modal.Header>
            <Text id='modal-title' size={22} css={{display: 'inline'}}>Welcome to</Text>
            <Text color="inherit" size={22} weight="semibold" css={{display: 'inline', textGradient: "45deg, $yellow600 0%, $green600 90%",}}>&nbsp;Oink</Text>
            <Text color="inherit" size={22} weight="black" css={{display: 'inline', textGradient: "45deg, $cyan700 -5%, $pink600 90%",}}>DB</Text>
          </Modal.Header>
          <Modal.Body>
            <Text color='error' visible={this.state.showHelperText.toString()} css={{display: 'block'}}>{this.state.helperText}</Text>
            <Input clearable bordered fullWidth color='primary' size='lg' placeholder='User' name='user' onChange={this.handleChange}/>
            <Input.Password clearable bordered fullWidth color='primary' size='lg' placeholder='Password' name='pass' onChange={this.handleChange} onKeyUp={this.handleKeyPress}/>
          </Modal.Body>
          <Modal.Footer>
              <Button auto bordered color={'success'} onPress={this.handleLogin}>Sign In</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    )
  }
}