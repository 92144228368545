import React, { useEffect, useState } from 'react';
import client from '../utils/feathers';
import { Text, Card, Spacer, Loading, Container, Row, Col, Table, Switch  } from '@nextui-org/react';
import CallPlayer from '../Components/CallPlayer';

var services = {};
services.calls = client.service('calls');
services.radios = client.service('radio-ids');
services.talkgroups = client.service('talkgroups');
services.calltime = client.service('calltime');
services.transcription = client.service('transcription');

var didInit = false;

export default function Home() {
  document.title = "OinkDB: Home";

  var liveCallColumns = [
    { key: "call_length", label: "Length" },
    { key: "talkgroup", label: "Talkgroup" },
    { key: "start_time", label: "Call Time" },
  ];

  const [totalCalls, setTotalCalls] = useState(0);
  const [radioIDs, setRadioIDs] = useState(0);
  const [talkgroups, setTalkgroups] = useState(0);
  const [transcribedCalls, setTranscribedCalls] = useState(0);
  const [callTime, setCallTime] = useState(0);
  const [callList, setCallList] = useState([]);
  const [selectedTableRow, setSelectedTableRow] = useState();
  const [selectedCall, setSelectedCall] = useState();
  const [autoPlay, setAutoPlay] = useState(true);
  const [autoPlayNew, setAutoPlayNew] = useState(true);
  const [mediaPlaying, setMediaPlaying] = useState(false);

  async function grabCallNum() {
    const calls = await services.calls.find({query: {$limit: 30, $sort: { start_time: -1 }}});
    setTotalCalls(calls.total);
    setCallList(calls.data);
    checkStartPlaying(calls.data[0].id);
  }

  async function grabRadioIDs() {
    const ids = await services.radios.find({query: {$limit: 0}});
    setRadioIDs(ids.total);
  }

  async function grabTranscribedCalls() {
    const calls = await services.transcription.find({query: {$limit: 0}});
    setTranscribedCalls(calls.total);
  }

  async function grabTalkgroups() {
    const tgs = await services.talkgroups.find({query: {$limit: 0}});
    setTalkgroups(tgs.total);
  }

  async function grabCallTime() {
    const calltime = await services.calltime.find();
    setCallTime(((calltime / 60) / 60).toFixed(2));
  }

  function createListener() {
    services.calls.on('created', (data) => { 
      setTimeout(() => {
        services.calls.get(data.id).then((call) => {
          setCallList(calls => [call, ...calls.slice(0, 100)]);    
          checkStartPlaying(call.id);
        });
      }, 800);
    })
  }

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      grabCallNum();
      grabRadioIDs();
      grabTranscribedCalls();
      grabTalkgroups();
      grabCallTime();
    }

    createListener();

    return () => {
      services.calls.removeListener('created');
    }
  }, [])

  async function tableItemSelect(selection) {
    setSelectedTableRow(selection);
    if (selection.anchorKey !== selectedCall) {
      setSelectedCall(selection.anchorKey)
    }
  }

  function renderTableCell(item, key) {
    if (key === 'start_time') {
      return new Date(item[key]).toLocaleString('en-US');
    } else if (key === 'talkgroup') {
      if (item['talkgroupInfo'].tag.length === 0) {
        return item['talkgroupInfo'].talkgroup;
      } else {
        return item['talkgroupInfo'].tag;
      }
    } else {
      return item[key];
    }
  }

  function audioEnded() {
    if (autoPlay) {
      var currentIndex = callList.findIndex(x => x.id === selectedCall);
      var newIndex;
      if (autoPlayNew) {
        newIndex = currentIndex - 1;
      } else {
        newIndex = currentIndex + 1;
      }
      if (newIndex >= 0 && newIndex < callList.length) {
        var newSelection = new Set();
        newSelection.add(callList[newIndex].id);
        newSelection.anchorKey = callList[newIndex].id;
        newSelection.currentKey = callList[newIndex].id;
        setSelectedTableRow(newSelection);
        setSelectedCall(callList[newIndex].id);
      } else {
        setMediaPlaying(false);
      }
    }
  }

  function audioStarted() {
    setMediaPlaying(true);
  }

  function checkStartPlaying(id) {
    console.log(autoPlay, mediaPlaying)
    if (autoPlay) {
      if (!mediaPlaying) {
      const callID = id || callList[0].id;
      var newSelection = new Set();
      newSelection.add(callID);
      newSelection.anchorKey = callID;
      newSelection.currentKey = callID;
      setSelectedTableRow(newSelection);
      setSelectedCall(callID);
      }
    }
  }

  return (
    <>
      <Container direction='column' gap={0}>
        <Row gap={1}>
          <Col span={12}>
            <Card>
              <Card.Header><Text h3>Statistics</Text></Card.Header>
              <Card.Body>
                <Row>
                  <Col css={{textAlign: 'center'}}>
                    <Text b>Calls</Text>
                    <Spacer />
                    {(totalCalls === 0) ? 
                      <Loading type="points">Loading</Loading>
                      :
                      <Text b css={{textGradient: "45deg, $yellow600 0%, $green600 90%",}} size="$3xl">{totalCalls}</Text>  
                    }
                  </Col>
                  <Col css={{textAlign: 'center'}}>
                    <Text b>Transcribed</Text>
                    <Spacer />
                    {(transcribedCalls === 0) ? 
                      <Loading type="points">Loading</Loading>
                      :
                      <Text b css={{textGradient: "45deg, $green600 10%, $cyan600 90%",}} size="$3xl">{transcribedCalls}</Text>  
                    }
                  </Col>
                  <Col css={{textAlign: 'center'}}>
                    <Text b>Radio IDs</Text>
                    <Spacer />
                    {(radioIDs === 0) ?
                      <Loading type="points">Loading</Loading>
                      :
                      <Text b css={{textGradient: "45deg, $cyan600 10%, $purple600 90%",}} size="$3xl">{radioIDs}</Text>
                    }
                  </Col>
                  <Col css={{textAlign: 'center'}}>
                    <Text b>Talkgroups</Text>
                    <Spacer />
                    {(talkgroups === 0) ?
                      <Loading type="points">Loading</Loading>
                      :
                      <Text span b css={{textGradient: "45deg, $purple600 10%, $pink600 90%",}} size="$3xl">{talkgroups}</Text>
                    }
                  </Col>
                  <Col css={{textAlign: 'center'}}>
                    <Text b>Hours of Audio</Text>
                    <Spacer />
                    {(callTime === 0) ?
                      <Loading type="points">Loading</Loading>
                      :
                      <Text span b css={{textGradient: "45deg, $pink600 10%, $yellow600 90%",}} size="$3xl">{callTime}</Text>
                    }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Spacer y={1} />
        <Row gap={1}>
          <Col span={8}>
            <Card>
              <Card.Header><Text h3>Latest Calls</Text></Card.Header>
              <Card.Divider />
              <Card.Body>
                <Table selectionMode='single' lined headerLined compact striped onSelectionChange={(key) => tableItemSelect(key)} selectedKeys={selectedTableRow}>
                  <Table.Header columns={liveCallColumns}>
                    {(column) => (
                      <Table.Column key={column.key}>{column.label}</Table.Column>
                    )}
                  </Table.Header>
                  <Table.Body items={callList}>
                    {(item) => (
                      <Table.Row key={item.key}>
                        {(columnKey) => (<Table.Cell>{renderTableCell(item, columnKey)}</Table.Cell>)}
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col span={4} css={{ position: 'sticky', top: '90px'}}>
            <Row>
              <CallPlayer call={selectedCall} onAudioStarted={audioStarted} onAudioEnded={audioEnded} />
            </Row>
            <Spacer y={1} />
            <Row>
              <Card>
                <Card.Header><Text h3>Settings</Text></Card.Header>
                <Card.Divider />
                <Card.Body>
                  <label>Auto Play:</label><Switch squared size={'xs'} name='autoPlay' checked={autoPlay} onChange={(e) => {setAutoPlay(e.target.checked); console.log(e.target.checked);}} />
                </Card.Body>
              </Card>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
